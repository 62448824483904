<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <div class="login-card" style="display: block">
            <div>
              <div class="mt-5">
                <a class="logo">
                  <img
                    class="img-fluid for-light"
                    src="@/assets/images/PSICOMETRIX-logo.jpg"
                    alt="looginpage"
                    width="170px"
                  />
                  <img
                    class="img-fluid for-dark"
                    src="@/assets/images/logo/logo_dark.png"
                    alt="looginpage"
                  />
                </a>
              </div>

              <div class="login-main login-form-card shadow">
                <div class="row" v-show="payment.length == 0">
                  <div class="col-md-12 text-center">
                    <div class="loader-box">
                      <div class="loader-1"></div>
                    </div>
                  </div>
                </div>

                <div
                  class="alert alert-danger outline font-unv"
                  role="alert"
                  v-if="payment.length > 0 && payment[0].status !== 1"
                >
                  <p>
                    Los procesos asociados a este pago ya fueron ejecutados, si
                    presento algún inconveniente pónganse en contacto con la
                    administración del sistema mediante el correo electrónico
                    informatica@psicometrix.org.
                  </p>
                </div>

                <div
                  class="row"
                  v-if="payment.length > 0 && payment[0].status === 1"
                >
                  <div class="col-md-12 font-unv mb-2">
                    <span>
                      Para finalizar el proceso de pago seleccione el medio de
                      pago
                    </span>
                  </div>

                  <div class="col-md-12 text-center mb-2 mt-3">
                    <button
                      class="btn btn-primary btn-block font-unv"
                      @click="flow"
                    >
                      <strong class="h6 mt-2"
                        >Pagar con Tarjeta o Otros Medios</strong
                      >
                    </button>
                  </div>

                  <div class="col-md-12 text-center mb2 mt-3">
                    <button
                      class="btn btn-outline-success btn-block font-unv"
                      @click="buttonTransfer"
                    >
                      <strong class="h6 mt-2"
                        >Pagar por Transferencia Bancaria</strong
                      >
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <p class="mt-4 mb-0 font-unv">
                      ¿Quieres realizar un nuevo registro?

                      <router-link class="ml-2" tag="a" to="/">
                        <strong>Click aqui</strong>
                      </router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Payment",
  data() {
    return {
      viewInfoTranferencia: false,
    };
  },
  computed: {
    ...mapState("Register", ["register"]),
    ...mapState("Payments", ["payment"]),
  },
  methods: {
    ...mapActions("Payments", ["getPaymentByCode"]),
    buttonTransfer() {
      const queryString = `commerceOrder=${this.payment[0].order}&currency=CLP&amount=${this.payment[0].amount}&email=${this.payment[0].email}&paymentMethod=22&subject=${this.payment[0].subject}&name=${this.payment[0].name}&dni=${this.payment[0].dni}`;

      this.$swal({
        title: "Información",
        html: "<span class='font-unv'>Le sugerimos completar el proceso en la plataforma de pago hasta ser redirigido a nuestro sitio web, para asegurar el registro de su transacción</span>",
        showCancelButton: false,
        confirmButtonText: "Continuar",
        confirmButtonColor: "#51BB25",
        cancelButtonText: "No",
        cancelButtonColor: "#BD2130",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.value) {
          window.open(
            `https://payments.psicometrix.cl/createPayment.php?${queryString}`,
            "_top"
          );
        } else {
        }
      });
    },
    async flow() {
      const queryString = `commerceOrder=${this.payment[0].order}&currency=CLP&amount=${this.payment[0].amount}&email=${this.payment[0].email}&paymentMethod=9&subject=${this.payment[0].subject}&name=${this.payment[0].name}&dni=${this.payment[0].dni}`;

      this.$swal({
        title: "Información",
        html: "<span class='font-unv'>Le sugerimos completar el proceso en la plataforma de pago hasta ser redirigido a nuestro sitio web, para asegurar el registro de su transacción</span>",
        showCancelButton: false,
        confirmButtonText: "Continuar",
        confirmButtonColor: "#51BB25",
        cancelButtonText: "No",
        cancelButtonColor: "#BD2130",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.value) {
          window.open(
            `https://payments.psicometrix.cl/createPayment.php?${queryString}`,
            "_top"
          );
        } else {
        }
      });

      // console.log(`https://payments.psicometrix.cl/createPayment.php?${queryString}`);

      try {
      } catch (error) {
        throw error;
      }
    },
    getpayment(paymentCode) {
      try {
        const pay = this.getPaymentByCode(paymentCode);
      } catch (error) {
        throw error;
      }
    },
  },
  created() {
    const code = this.$route.params.code;
    this.getpayment(code);
  },
};
</script>
